//
// Card group
//


.card-group {
    .card {
        .mask {
            border-radius: 0;
        }
    }
}
