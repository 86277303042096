//
// Labeled button


.btn-action-label {
    .btn-label:hover {
        background: transparent;
        color: $gray-800;
    }

    .btn-secondary:hover {
        color: theme-color("primary");
    }
}


// Icon labels

.btn-icon-label {
    position: relative;

    .btn-inner--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 100%;
        line-height: 1;
        border-radius: 0;
        text-align: center;
        margin: 0;
        width: 3em;
        background-color: rgba(0, 0, 0, .1);
    }

    .btn-inner--icon:not(:first-child) {
        right: 0;
        top: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
    }

    .btn-inner--icon:not(:last-child) {
        left: 0;
        top: 0;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
    }

    .btn-inner--text:not(:first-child) {
        padding-left: 3em;
    }

    .btn-inner--text:not(:last-child) {
        padding-right: 3em;
    }
}
