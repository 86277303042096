//
// Divider
//

.divider {
    position: relative;
    border: 0;
    width: 100%
}

.divider-md {
    width: 60%;
}

.divider-sm {
    width: 40%;
}
