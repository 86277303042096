// Image alignment
.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Image filters
.img-grayscale {
    filter: grayscale(100%);
}

.img-saturate {
    filter: saturate(150%)
}

// Add a CVG background behind <img>
.img-back-shape {
    img {
        background: url(../img/svg/backgrounds/bg-circles-1.svg) no-repeat scroll center center/100% 100% border-box;
    }
}
