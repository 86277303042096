//
// Divider vertical
//

.divider-vertical {
    width: $divider-border-width;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    border-left: 1px solid $divider-border-color;
}

.divider-vertical-fade {
    border: 0;
    background: radial-gradient(ellipse at center, $divider-border-color 0, rgba(255, 255, 255, 0) 75%);
}
