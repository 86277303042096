//
// Tongue
//

.tongue {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    transform: rotate(180deg) translateX(50%);
    width: 138px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: $gray-700;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='138' height='35' viewBox='0 0 138 35'%3E%3Cpath fill='#{$tongue-bg}' d='M 3.15027 34.4375C 1.74207 34.6025 0.68396 34.7266 0 35L 68.9982 35C 68.9982 35 68.9998 28.6387 69 19.5132C 69 20.7261 69.0001 21.8892 69.0001 22.9956L 69.0018 35L 138 35C 137.316 34.7266 136.258 34.6025 134.85 34.4375C 131.11 33.9995 124.903 33.2729 116.678 28.6875C 111.015 25.3901 106.523 20.9771 102.07 16.6025C 93.5474 8.23096 85.1685 -1.97984e-15 69.0018 3.55056e-31C 69.0006 5.271 69 10.4292 69 15.0952C 69 10.4287 68.9994 5.27148 68.9982 3.55056e-31C 52.8314 1.97986e-15 44.4526 8.23096 35.9302 16.6025C 31.4772 20.9771 26.985 25.3901 21.3221 28.6875C 13.0974 33.2729 6.88965 33.9995 3.15027 34.4375Z'/%3E%3C/svg%3E"), "#", "%23");

    i {
        animation: floating-sm 2s ease infinite;
    }

    &:hover {

        i {
            animation-play-state: paused;
        }
    }
}

// Tongue backgrounds

@each $color, $value in $theme-colors {
    @include bg-tongue-variant(".tongue-#{$color}", $value);
}

@each $color, $value in $section-colors {
    @include bg-tongue-variant(".tongue-section-#{$color}", $value);
}

// Tongue positions

.tongue-top {
    top: -1px;
}
.tongue-bottom {
    top: auto;
    bottom: -1px;
    transform: translateX(-50%);
}

// Keyframes

@keyframes floating-sm {
    0% {
        transform: translateY(0px)
    }
    50% {
        transform: translateY(5px)
    }
    100% {
        transform: translateY(0px)
    }
}
