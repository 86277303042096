//
// Body mask
//


.mask-body {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1040; // navbar fixed has a z-index of 1030
}

.mask-body-dark {
    background: rgba($black, .3);
}

.mask-body-light {
    background: rgba($white, .3);
}
