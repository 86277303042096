//
// Form stacked
//


.form-stacked {
    .form-control {
        position: relative;
        box-sizing: border-box;

        &:first-of-type {
            margin-bottom: -1px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:first-of-type):not(:last-of-type) {
            margin-bottom: -1px;
            border-radius: 0;
        }

        &:last-of-type {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        &:focus {
            z-index: 2;
        }
    }
}
