//
// Milestone counter
//


.milestone-count-extra {
	display: none;
}

.counting-finished + .counter-extra {
	display: inline-block;
}
